<template>
  <div>
  <TopMenuSubheadline :title="title" />
  <div class="app-content-box bg-gray">
    <ul class="category-collapse-list wrapper">
    <div v-for="(item, key) in genres" :key="key">
      <li v-b-toggle="'cat-' + key" @click="toggleCat(item.label, item.id, item.children)">
        <div class="container">
          <a class="category-element" :class="{'category-collapse-link': item.children.length,  'category-element-checked': isCatChecked.filter(function(el) {return item.label == el}).length > 0}" :id="'id-' + key" data-toggle="collapse" >
            {{item.label}}
          </a>
        </div>
      </li>
      <b-collapse :id="'cat-' + key">
      <div>
          <div v-if="item.children" class="collapse category-collapse-content show" >
            <ul>
              <li v-for="(sub, i) in item.children" :key="i" @click="toggleSubcat(item.label, sub)">
                <a :class="{'category-element-checked': isSubChecked.filter(function(el) {return el === (sub.id)}).length > 0}">{{sub.label}}</a>
              </li>
            </ul>
          </div>
      </div>
    </b-collapse>
    </div>
    </ul>
        <div class="category-btn bg-gray" @click="$router.go(-1)"><button class="btn-black-full w-100 mt-2 mb-3">Pokaż wyniki</button></div>
  </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
export default {
  name: 'CheckCategories',
  components: {
    TopMenuSubheadline
  },
  data () {
    return {
      genres: [],
      title: 'Wszystkie kategorie',
      isCatChecked: [],
      isSubChecked: [],
      addBookSelectedGenres: []
    }
  },
  async created () {
    this.selectedGenres = []
    await this.$https('/genres', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {

      }
    })
      .then(res => {
        this.genres = res.data.response
      })
      .catch(error => {
        console.log('', error)
      })
  },
  computed: {
    selectedGenres: {
      get () {
        return this.$store.state.addBookSelectedGenres
      },
      set (value) {
        this.$store.commit('updateAddBookSelectedGenres', value)
      }
    }
  },
  methods: {
    toggleCat (item, id, sub) {
      let isSelected = false
      let toDelete = 0
      if (!sub.length) {
        this.isCatChecked.forEach(element => {
          if (element === item) {
            isSelected = true
            toDelete = this.isCatChecked.indexOf(element)
          }
        })
        let unselectGenre = 0
        this.selectedGenres.forEach(el => {
          if (el.id === id) {
            unselectGenre = this.selectedGenres.indexOf(el)
          }
        })
        if (isSelected === false) {
          this.isCatChecked.push(item)
          this.selectedGenres.push({ id: id, label: item })
        } else {
          this.isCatChecked.splice(toDelete, 1)
          this.selectedGenres.splice(unselectGenre, 1)
        }
      }
    },
    toggleSubcat (item, sub) {
      let isSelected = false
      let toDelete = 0
      this.isSubChecked.forEach(element => {
        if (element === sub.id) {
          isSelected = true
          toDelete = this.isSubChecked.indexOf(element)
        }
      })
      let unselectGenre = 0
      this.selectedGenres.forEach(el => {
        if (el.id === sub.id) {
          unselectGenre = this.selectedGenres.indexOf(el)
        }
      })
      if (isSelected === false) {
        this.isSubChecked.push(sub.id)
        this.selectedGenres.push({ id: sub.id, label: sub.label })
      } else {
        this.isSubChecked.splice(toDelete, 1)
        this.selectedGenres.splice(unselectGenre, 1)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrapper {
    padding-top: 40px;
    margin-bottom: 140px;
  }
  .category-element {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      display: block;
      color: #000;
  }
  .collapse .category-collapse-content {
    a {
      color: #000;
      font-weight: 400;
    }
  }

  .category-collapse-list li {
    border-bottom: none;
  }
  .category-element-checked {
    &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 12px;
        content: "\f00c";
        margin-right: 5px;
    }
  }
  .category-btn {
    position: fixed;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
    max-width: 360px;
  }
</style>
